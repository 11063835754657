import SearchActions from "@/components/search/SearchActions";
import useSearchSubmit from "@/hooks/useSearchSubmit";
import Container from "@/ui/Container";
import {
  Box,
  Button,
  Image,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
} from "@chakra-ui/react";
import { useRef } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Form, Link } from "react-router-dom";

const SearchEngine = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const handleSubmit = useSearchSubmit(formRef);
  const { t } = useTranslation();
  return (
    <Container hasSearch>
      <Box
        mt={8}
        p={[4, 8]}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={4}
        w={"100%"}
        h={"100%"}
        borderRadius={24}
        bgColor={"#F2F6F9"}
      >
        <Box
          flexGrow={1}
          display={"flex"}
          flexDir={"column"}
          alignItems={"stretch"}
          gap={8}
          pt={"15%"}
        >
          <Image
            src="/images/logo-full.svg"
            alt="Paperdoc"
            width={300}
            mx={"auto"}
          />
          <Text textAlign={"center"} fontSize={18} fontStyle={"italic"}>
            {t("search.subtitle")}
          </Text>

          <Form
            method="get"
            action="/search"
            ref={formRef}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Box display={"flex"} justifyContent={"center"}>
              <InputGroup
                bgColor={"#fff"}
                borderRadius={50}
                maxW={"800px"}
                _focusWithin={{
                  outline: "2px solid #00BCB0",
                }}
              >
                <Input
                  type="text"
                  placeholder={t("search.placeholder")}
                  name="q"
                  id="q"
                  border={"none"}
                  borderRadius={50}
                  h={16}
                  required
                  w={"100%"}
                  inputMode="search"
                  _focusVisible={{
                    outline: "none",
                  }}
                />
                <InputRightAddon
                  bgColor={"#fff"}
                  border={"none"}
                  borderRadius={50}
                  h={16}
                  p={1}
                  pl={0}
                >
                  <Button
                    h={14}
                    borderRadius={50}
                    variant={"primary"}
                    type="submit"
                  >
                    {t("search.submit")}
                  </Button>
                </InputRightAddon>
              </InputGroup>
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
              <SearchActions />
            </Box>
          </Form>

          <Text
            textAlign={"center"}
            fontSize={18}
            fontStyle={"italic"}
            sx={{
              a: {
                color: "brand.500",
                textDecoration: "underline",
              },
              textWrap: "balance",
            }}
          >
            <Trans i18nKey="search.help" t={t}>
              Parcourez une base de données de +200 millions d'articles couvrant
              tous les domaines scientifiques. Pour en savoir plus, allez sur la
              rubrique
              <Link to={"/help"}>'Comment ça marche?'</Link>.
            </Trans>
          </Text>
        </Box>
      </Box>
    </Container>
  );
};

export default SearchEngine;
