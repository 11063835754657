import useArchieData from "@/hooks/useArchieData";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tag,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const answerColors = {
  yes: "green.500",
  no: "red.500",
  potentially: "yellow.500",
  unknown: "gray.500",
};

const SearchArticleAnswer = ({ corpusId }: { corpusId: string | number }) => {
  const id = typeof corpusId === "string" ? corpusId : corpusId.toString();
  const { archieYO, selectedAnswer, filterByAnswer } = useArchieData(
    (state) => state
  );
  const { t } = useTranslation();

  const getAnswer = () => {
    if (!archieYO) return "unknown";

    const answers = Object.entries(selectedAnswer).flatMap(([key, value]) => {
      if (value) return key;
      return [];
    }) as ("yes" | "no" | "potentially")[];
    if (answers.length === 1 && filterByAnswer) {
      return answers[0];
    } else if (answers.length > 1 && filterByAnswer) {
      return (
        answers.find((answer) => archieYO.answer[answer].includes(id)) ||
        "unknown"
      );
    }

    if (archieYO.answer.yes.includes(id)) return "yes";
    if (archieYO.answer.no.includes(id)) return "no";
    if (archieYO.answer.potentially.includes(id)) return "potentially";
    return "unknown";
  };

  const answer = getAnswer();

  if (answer === "unknown") return null;

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Tag
          minW={"fit-content"}
          color={"white"}
          bgColor={answerColors[answer]}
        >
          {t(`archie.yes_no.${answer}`)}
        </Tag>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <Text>{archieYO?.query}</Text>
          <Text fontWeight={"bold"}>
            {t(`archie.yes_no.tags.answer`, {
              answer: t(`archie.yes_no.${answer}`),
            })}
          </Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default SearchArticleAnswer;
