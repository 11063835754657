import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  SkeletonText,
  Tag,
  Text,
} from "@chakra-ui/react";
import parseText from "./ArchieQ/TextParser";
import useArchieData from "@/hooks/useArchieData";

import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { runArchieQ } from "@/lib/cloudFunctions";
import { doc, onSnapshot } from "firebase/firestore";
import { dbIa } from "@/lib/firebase";

const ArchieQ = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [sourceCount, setSourceCount] = useState(1);
  const [refs, setRefs] = useState<Set<string>>(new Set());
  const {
    archieQ,
    additionalQuestions,
    setArchieData,

    currentQuery,

    setRefs: setArchieRefs,
    token,
  } = useArchieData((state) => state);

  const runArchie = useCallback(async () => {
    const response = await runArchieQ({
      token,
      query: currentQuery,
      first_request: true,
    });
    setArchieRefs(new Set(response.data.papers));
    setRefs((prev) => new Set([...prev, ...response.data.papers]));

    const { parts, currentSource } = parseText(
      response.data.answer,

      sourceCount
    );
    setSourceCount(currentSource);
    setArchieData({
      archieQ: {
        ...response.data,
        answer: parts,
      },
      additionalQuestions: response.data.questions.map((question) => ({
        question: question,
      })),
    });

    setLoading(false);
  }, [currentQuery, setArchieData, sourceCount, setArchieRefs, token]);

  useEffect(() => {
    if (currentQuery === "" || archieQ) {
      if (archieQ) {
        setLoading(false);
        setRefs(new Set(archieQ.papers));
      }
      return () => {};
    }

    const unsub = onSnapshot(doc(dbIa, "ai_searches", token), (doc) => {
      if (doc.exists()) {
        runArchie();
      }
    });
    return () => unsub();
  }, [currentQuery, archieQ, runArchie, token]);

  if (loading) return <SkeletonText w={"100%"} noOfLines={2} spacing={4} />;
  if (!archieQ) return null;
  const loadAdditionalQuestions = async (index: number) => {
    if (index <= 0) return;

    if (additionalQuestions[index].answer) {
      return;
    }

    const response = await runArchieQ({
      query: additionalQuestions[index].question,
      token,
      first_request: false,
    });

    const { parts, currentSource } = parseText(
      response.data.answer,

      sourceCount
    );
    setSourceCount(currentSource);
    setArchieRefs(new Set(response.data.papers));
    setRefs((prev) => new Set([...prev, ...response.data.papers]));

    setArchieData({
      additionalQuestions: additionalQuestions.map((question, i) =>
        i === index ? { ...question, answer: parts } : question
      ),
    });
  };
  return (
    <Box>
      <Tag mt={1}>
        {t("archie.papersAnalysed", {
          count: refs.size,
        })}
      </Tag>
      <HStack gap={4} flexWrap={"wrap"} mt={4}></HStack>

      <Accordion
        defaultIndex={0}
        allowToggle
        onChange={loadAdditionalQuestions}
      >
        <AccordionItem>
          <AccordionButton
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={4}
          >
            <Text
              textAlign={"left"}
              sx={{
                textWrap: "balance",
              }}
              fontWeight={"bold"}
            >
              {archieQ.questions[0]}
            </Text>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Text mt={4}>{archieQ.answer}</Text>
          </AccordionPanel>
        </AccordionItem>
        <Text fontWeight={"bold"} as={"h3"} size={"md"} my={4}>
          {t("archie.additional_questions")}
        </Text>
        {additionalQuestions.slice(1).map((question, index) => (
          <AccordionItem key={index}>
            <AccordionButton
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={4}
            >
              <Text
                textAlign={"left"}
                sx={{
                  textWrap: "balance",
                }}
                fontWeight={"bold"}
              >
                {question.question}
              </Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              {question.answer ? (
                <Text>{question.answer}</Text>
              ) : (
                <SkeletonText noOfLines={5} spacing={4} skeletonHeight={4} />
              )}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default ArchieQ;
