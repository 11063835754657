import useArchieData from "@/hooks/useArchieData";
import { Text } from "@chakra-ui/react";

const ArchieSource = ({ id }: { id: string }) => {
  const refs = useArchieData((state) => state.refs);
  const paper = [...refs].findIndex((p) => p === id);

  if (paper < 0) {
    return null;
  }

  const handleClick = () => {
    const element = document.querySelector(
      `#paper-${id} > div`
    ) as HTMLElement | null;
    if (!element) {
      return;
    }
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    //Animate element border color
    element.style.transition = "background-color 0.5s ease";
    element.style.backgroundColor = "#00BCB0";
    setTimeout(() => {
      element.style.backgroundColor = "#fff";
    }, 1000);
  };

  return (
    <Text
      cursor={"pointer"}
      color={"brand.500"}
      textDecoration={"underline"}
      as={"span"}
      onClick={handleClick}
    >
      {paper + 1}
    </Text>
  );
};

export default ArchieSource;
