import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Link as ChackraLink,
  Text,
  CardFooter,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Tldr from "./Tldr";
import semanticScholarApi, { PaperResponse } from "@/lib/SemanticScholarApi";
import slugify from "slugify";
import { useEffect, useState } from "react";

const HomeArticle = (props: PaperResponse) => {
  const {
    title,
    tldr,
    externalIds,
    journal,
    paperId,
    authors,
    year,
    publicationDate,
    citationCount,
    corpusId,
  } = props;
  const slug = slugify(title, {
    lower: true,
    strict: true,
  });
  const [tldrText, setTldrText] = useState<string | undefined>(tldr?.text);

  useEffect(() => {
    if (!tldr) {
      (async () => {
        const tlderRes = await semanticScholarApi.search.get(
          `/paper/${paperId}`,
          {
            params: {
              fields: "tldr",
            },
          }
        );
        setTldrText(tlderRes.data.tldr?.text);
      })();
    }
  }, [tldr, paperId]);

  return (
    <ChackraLink
      sx={{
        _hover: {
          textDecoration: "none",
        },
      }}
      as={Link}
      to={`/article/${slug}/${corpusId}`}
    >
      <Card bgColor={"#fff"} borderRadius={28} p={4}>
        <CardHeader pb={0}>
          <Heading
            fontFamily={"Be Vietnam Pro"}
            fontWeight={900}
            as={"h2"}
            size={"md"}
            mb={2}
          >
            {title}
          </Heading>
        </CardHeader>
        <CardBody py={4}>
          {tldrText && (
            <Text>
              <Tldr />
              {tldrText}
            </Text>
          )}
        </CardBody>
        <CardFooter pt={0}>
          <Text fontSize={12} fontStyle={"italic"}>
            {publicationDate
              ? new Date(publicationDate).toLocaleDateString()
              : year}{" "}
            | {journal && `${journal.name} | `}
            {authors.map((val) => val.name).join(", ")} et al.{" "}
            {externalIds.DOI ? `| ${externalIds.DOI}` : null}
            {citationCount ? `| ${citationCount} citations` : null}
          </Text>
        </CardFooter>
      </Card>
    </ChackraLink>
  );
};

export default HomeArticle;
