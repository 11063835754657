import {
  Heading,
  Tag,
  HStack,
  SkeletonText,
  Box,
  VStack,
  Text,
  IconButton,
  Checkbox,
  Flex,
  Button,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

import useArchieData from "@/hooks/useArchieData";
import { useCallback, useEffect, useState } from "react";
import { runArchieYO } from "@/lib/cloudFunctions";
import { CheckIcon, CloseIcon, MinusIcon } from "@chakra-ui/icons";
import { IoFilterOutline } from "react-icons/io5";
import { doc, onSnapshot } from "firebase/firestore";
import { dbIa } from "@/lib/firebase";

const ArchieYO = () => {
  const { t } = useTranslation();
  const {
    currentQuery,
    archieYO,

    setArchieData,
    toggleFilterByAnswer,
    filterByAnswer,
    setSelectedAnswers,
    selectedAnswer,
    token,
    setRefs,
  } = useArchieData((state) => state);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [refsCount, setRefsCount] = useState(1);
  const [answersWeight, setAnswersWeight] = useState({
    yes: 0,
    no: 0,
    potentially: 0,
  });
  const fetchArchieYO = useCallback(async () => {
    setError(false);
    setLoading(true);
    try {
      const res = await runArchieYO({
        token,
      });
      setRefs(new Set(res.data.papers));
      setArchieData({
        archieYO: res.data,
      });

      setRefsCount(res.data.papers.length);

      const yesWeight = Math.round(
        (res.data.answer.yes.length / res.data.papers.length) * 100
      );
      const noWeight = Math.round(
        (res.data.answer.no.length / res.data.papers.length) * 100
      );
      const potentiallyWeight = 100 - yesWeight - noWeight;
      setAnswersWeight({
        yes: yesWeight,
        no: noWeight,
        potentially: potentiallyWeight,
      });
    } catch (error) {
      setError(true);
    }

    setLoading(false);
  }, [token, setArchieData, setRefs]);
  useEffect(() => {
    if (currentQuery === "" || archieYO) {
      if (archieYO) {
        setLoading(false);
        setRefsCount(archieYO.papers.length);
        const yesWeight = Math.round(
          (archieYO.answer.yes.length / archieYO.papers.length) * 100
        );
        const noWeight = Math.round(
          (archieYO.answer.no.length / archieYO.papers.length) * 100
        );
        const potentiallyWeight = 100 - yesWeight - noWeight;
        setAnswersWeight({
          yes: yesWeight,
          no: noWeight,
          potentially: potentiallyWeight,
        });
      }
      return () => {};
    }

    const unsub = onSnapshot(doc(dbIa, "ai_searches", token), (doc) => {
      if (doc.exists()) {
        fetchArchieYO();
      }
    });

    return () => unsub();
  }, [currentQuery, archieYO, fetchArchieYO, token]);
  if (loading) return <SkeletonText noOfLines={2} spacing={4} minW={"400px"} />;

  if (error) {
    return (
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        gap={4}
        marginY={"auto"}
        minW={"400px"}
      >
        <Text> {t("archie.error")}</Text>
        <Button onClick={fetchArchieYO}>{t("archie.retry")}</Button>
      </Box>
    );
  }
  if (!archieYO) return null;
  return (
    <Box minW={["auto", "auto", "auto", "auto", "400px"]}>
      <Tag mt={1}>
        {t("archie.papersAnalysed", {
          count: refsCount,
        })}
      </Tag>
      <HStack mt={4} gap={4} justifyContent={"space-between"}>
        <Heading
          as={"h3"}
          size={"sm"}
          textAlign={"left"}
          sx={{
            textWrap: "balance",
          }}
        >
          {archieYO.query}
        </Heading>

        <IconButton
          aria-label="Filter by answer"
          variant={"ghost"}
          icon={<IoFilterOutline size={26} />}
          onClick={toggleFilterByAnswer}
        />
      </HStack>
      {refsCount > 0 ? (
        <Flex gap={2} flexWrap={"wrap"}>
          <VStack mt={4} spacing={4} align={"stretch"}>
            <HStack spacing={2} justify={"space-between"}>
              <HStack spacing={2}>
                {filterByAnswer ? (
                  <Checkbox
                    isChecked={selectedAnswer.yes}
                    onChange={() =>
                      setSelectedAnswers({
                        ...selectedAnswer,
                        yes: !selectedAnswer.yes,
                      })
                    }
                  />
                ) : (
                  <CheckIcon color={"green.500"} />
                )}

                <Text width={"18ch"} fontWeight={"bold"}>
                  {t("archie.yes_no.yes")} - {answersWeight.yes}%
                </Text>
              </HStack>
            </HStack>
            <HStack spacing={2} justify={"space-between"}>
              <HStack spacing={2}>
                {filterByAnswer ? (
                  <Checkbox
                    isChecked={selectedAnswer.potentially}
                    onChange={() =>
                      setSelectedAnswers({
                        ...selectedAnswer,
                        potentially: !selectedAnswer.potentially,
                      })
                    }
                  />
                ) : (
                  <MinusIcon color={"yellow.500"} />
                )}
                <Text width={"18ch"} fontWeight={"bold"}>
                  {t("archie.yes_no.potentially")} - {answersWeight.potentially}
                  %
                </Text>
              </HStack>
            </HStack>
            <HStack spacing={2} justify={"space-between"}>
              <HStack spacing={2}>
                {filterByAnswer ? (
                  <Checkbox
                    isChecked={selectedAnswer.no}
                    onChange={() =>
                      setSelectedAnswers({
                        ...selectedAnswer,
                        no: !selectedAnswer.no,
                      })
                    }
                  />
                ) : (
                  <CloseIcon color={"red.500"} />
                )}

                <Text width={"18ch"} fontWeight={"bold"}>
                  {t("archie.yes_no.no")} - {answersWeight.no}%
                </Text>
              </HStack>
            </HStack>
          </VStack>

          <ResponsiveContainer height={150} width={150}>
            <PieChart>
              <Pie
                data={[
                  {
                    name: t("archie.yes_no.yes"),
                    value: archieYO.answer.yes.length,
                  },
                  {
                    name: t("archie.yes_no.potentially"),
                    value: archieYO.answer.potentially.length,
                  },
                  {
                    name: t("archie.yes_no.no"),
                    value: archieYO.answer.no.length,
                  },
                ]}
                dataKey="value"
                nameKey="name"
                paddingAngle={5}
                innerRadius={45}
                outerRadius={65}
                isAnimationActive
                fill="#8884d8"
              >
                <Cell fill={"#38A169"} />
                <Cell fill={"#D69E2E"} />
                <Cell fill={"#E53E3E"} />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Flex>
      ) : (
        <Text mt={4}>{t("archie.noRelevantPapers")}</Text>
      )}
    </Box>
  );
};

export default ArchieYO;
