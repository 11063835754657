import { Box, Heading, SkeletonText, Text } from "@chakra-ui/react";
import parseText from "./ArchieQ/TextParser";
import useArchieData from "@/hooks/useArchieData";
import { useCallback, useEffect, useState } from "react";
import { runArchieSumm } from "@/lib/cloudFunctions";
import { doc, onSnapshot } from "firebase/firestore";
import { dbIa } from "@/lib/firebase";

const ArchieSumm = () => {
  const {
    archieSUMM,
    setArchieData,
    currentQuery,

    token,
    setRefs,
  } = useArchieData((state) => state);

  const [loading, setLoading] = useState(true);

  const fetchArchieSumm = useCallback(async () => {
    setLoading(true);
    const res = await runArchieSumm({
      token,
    });
    setRefs(new Set(res.data.papers));
    setArchieData({
      archieSUMM: res.data,
    });

    setLoading(false);
  }, [token, setArchieData, setRefs]);

  useEffect(() => {
    if (currentQuery === "" || archieSUMM) {
      if (archieSUMM) {
        setLoading(false);
      }
      return () => {};
    }

    const unsub = onSnapshot(doc(dbIa, "ai_searches", token), (doc) => {
      if (doc.exists()) {
        fetchArchieSumm();
      }
    });
    return () => unsub();
  }, [currentQuery, archieSUMM, fetchArchieSumm, token]);

  if (loading) return <SkeletonText noOfLines={2} spacing={4} mb={4} />;
  if (!archieSUMM) return null;

  let sourceCount = 1;

  return (
    <Box>
      <Box flexGrow={1} mb={4}>
        {archieSUMM.summary.sections.map((section, index) => {
          const { parts, currentSource } = parseText(
            section.content,

            sourceCount
          );
          sourceCount = currentSource;

          return (
            <div key={index}>
              <Heading as={"h4"} size={"md"} my={4}>
                {section.section_title}
              </Heading>
              {section.content ? (
                <Text>{parts.map((e) => e)}</Text>
              ) : (
                <>
                  {section.sub_sections.map((sub_section, sub_index) => {
                    const { parts, currentSource } = parseText(
                      sub_section.content,

                      sourceCount
                    );
                    sourceCount = currentSource;
                    return (
                      <div key={sub_index}>
                        <Heading as={"h5"} size={"sm"} my={3}>
                          {sub_section.sub_section_title}
                        </Heading>
                        <Text>{parts.map((e) => e)}</Text>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          );
        })}
      </Box>
    </Box>
  );
};

export default ArchieSumm;
